.Chat {
  display: flex;
  overflow: hidden;
  width: 100%;
  height: 100%;
  color: #fff;
}

.sideMenu {
  width: 260px;
  overflow-y: auto;
  flex-shrink: 0;
  background-color: rgba(0, 0, 0, 0.4);
}

.sideMenuContent {
  width: 100%;
  min-height: 100%;
  padding: 10px;
}

.nav {
  display: none;
}

/* under 680px do this */
@media (max-width: 680px) {
  .sideMenu {
    position: fixed;
    width: 100%;
    height: calc(100% - 42px);
    top: 42px;
    left: -100%;
    transition: left 0.25s;
    z-index: 8;
    background-image: linear-gradient(to right, var(--tw-gradient-stops));
  }

  .sideMenu[data-opened='true'] {
    left: 0;
  }

  .sideMenuContent {
    padding: 16px 36px;
    background-color: rgba(0, 0, 0, 0.4);
  }

  .nav {
    width: 100%;
    height: 42px;
    z-index: 16;
    background-color: rgba(0, 0, 0, 0.4);
    display: flex;
    align-items: center;
    padding: 0 24px;
    flex-shrink: 0;
  }

  .menuButton {
    display: inline-flex;
    padding: 8px;
    position: fixed;
    -webkit-tap-highlight-color: transparent;
  }
}

.sideMenuButton {
  padding: 12px;
  border: 1px solid white;
  border-radius: 5px;
  text-align: left;
  transition: ease 0.25s all;
  cursor: pointer;
}
.sideMenuButton:hover {
  background-color: rgba(255, 255, 255, 0.1);
}
.sideMenuButton span {
  padding-left: 6px;
  padding-right: 12px;
}

.chatBox {
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  min-width: 0;
}

.messages {
  flex-grow: 1;
  overflow-y: auto;
  text-align: left;
}

.chatFormWrapper {
  padding: 24px;
}

@media (max-width: 680px) {
  .chatFormWrapper {
    padding: 24px 12px;
  }
}

.chatMessage {
  color: #000;
}
.chatMessage.assistant {
  color: #fff;
  background-color: rgba(0, 0, 0, 0.4);
}
.chatMessageCenter {
  max-width: 680px;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  padding: 12px;
  padding-left: 24px;
  padding-right: 24px;
}
.avatar {
  border-radius: 50%;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px;
  font-size: 14px;
  color: #444654;
  flex-shrink: 0;
}
.avatar:not(.assistant) {
  background: white;
}
.avatar.assistant {
  border-radius: 50%;
  min-width: 40px;
  min-height: 40px;
  padding: 5px;
  color: white;
  background-repeat: no-repeat;
  background-position: center;
}
.message {
  padding-left: 40px;
  /* 40px is the avatar width */
  width: calc(100% - 40px);
  flex-shrink: 0;
}

@media (max-width: 680px) {
  .message {
    padding-left: 24px;
  }
}

.selectModels {
  border: 1px solid white;
  padding: 12px;
  border-radius: 5px;
  color: white;
  background: transparent;
  outline: none;
  cursor: pointer;
  max-width: 100%;
  min-width: 100%;
  font-family: inherit; /* 1 */
  font-size: 100%; /* 1 */
  line-height: 1.15; /* 1 */
  margin: 0; /* 2 */
  text-transform: none;
}
.selectModels option {
  background: black;
  color: white;
}

.buttonPicker {
  border: none;
  padding: 6px 12px;
  border-radius: 5px;
  color: white;
  background: #444654;
  margin-top: 6px;
  cursor: pointer;
}
.buttonPicker:hover {
  background: #626473;
}

.info {
  opacity: 0.66;
  font-size: 0.8em;
  margin-top: 6px;
  display: block;
  line-height: 1.5;
}

.sideLabel {
  font-size: 0.9em;
  margin-top: 1em;
  margin-bottom: 0.5em;
  padding-bottom: 0em;
  display: block;
}

.form {
  display: flex;
  gap: 6px;
}

.chatInputTextarea {
  flex-grow: 1;
  padding: 12px;
  border-radius: 5px;
  color: white;
  font-size: 16px;
  border: none;
  outline: none;
  border: 1px solid #d1d5db;
  color: #000;
  font-family: inherit; /* 1 */
  line-height: 1.15; /* 1 */
  margin: 0; /* 2 */
  overflow: visible;
  min-width: 0;
}

.submit {
  flex-shrink: 0;
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
  background: #0da37f;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px 12px;
  font-size: 14px;
  color: white;
  border-radius: 5px;
  cursor: pointer;
  font-family: inherit;
  line-height: 1.15;
  margin: 0;
  text-transform: none;
  overflow: visible;
}
.submit:hover {
  background: #066d55;
}

.submit::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

.submit:-moz-focusring {
  outline: 1px dotted ButtonText;
}

.blinker {
  animation-name: blinker;
  animation-iteration-count: infinite;
  animation-timing-function: cubic-bezier(1, 0, 0, 1);
  animation-duration: 1s;
}

@keyframes blinker {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
